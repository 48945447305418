define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu-composer", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comments-menu-composer">
    <PostVotingCommentComposer
      @onInput={{this.updateValue}}
      @onKeyDown={{this.onKeyDown}}
    />
  
    <DButton
      @action={{this.saveComment}}
      @disabled={{this.submitDisabled}}
      @icon="reply"
      @label="post_voting.post.post_voting_comment.submit"
      class="btn-primary post-voting-comments-menu-composer-submit"
    />
  
    <DButton
      @display="link"
      @action={{@onCancel}}
      @label="post_voting.post.post_voting_comment.cancel"
      class="post-voting-comments-menu-composer-cancel"
    />
  </div>
  */
  {
    "id": "PLapOuPX",
    "block": "[[[10,0],[14,0,\"post-voting-comments-menu-composer\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onInput\",\"@onKeyDown\"],[[30,0,[\"updateValue\"]],[30,0,[\"onKeyDown\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"btn-primary post-voting-comments-menu-composer-submit\"]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\"],[[30,0,[\"saveComment\"]],[30,0,[\"submitDisabled\"]],\"reply\",\"post_voting.post.post_voting_comment.submit\"]],null],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"post-voting-comments-menu-composer-cancel\"]],[[\"@display\",\"@action\",\"@label\"],[\"link\",[30,1],\"post_voting.post.post_voting_comment.cancel\"]],null],[1,\"\\n\"],[13]],[\"@onCancel\"],false,[\"post-voting-comment-composer\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments-menu-composer.hbs",
    "isStrictMode": false
  });
  let PostVotingCommentsMenuComposer = _exports.default = (_class = class PostVotingCommentsMenuComposer extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
      _initializerDefineProperty(this, "submitDisabled", _descriptor3, this);
    }
    onKeyDown(e) {
      if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
        this.saveComment();
      }
    }
    updateValue(value) {
      this.submitDisabled = value.length < this.siteSettings.min_post_length || value.length > this.siteSettings.post_voting_comment_max_raw_length;
      this.value = value;
    }
    saveComment() {
      this.submitDisabled = true;
      return (0, _ajax.ajax)("/post_voting/comments", {
        type: "POST",
        data: {
          raw: this.value,
          post_id: this.args.id
        }
      }).then(response => {
        this.args.onSave(response);
        this.value = "";
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.submitDisabled = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "submitDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveComment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveComment"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentsMenuComposer);
});