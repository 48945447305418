define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comments">
    {{#each this.comments as |comment|}}
      <PostVotingComment
        @comment={{comment}}
        @removeComment={{this.removeComment}}
        @updateComment={{this.updateComment}}
        @vote={{this.vote}}
        @removeVote={{this.removeVote}}
        @disabled={{this.disabled}}
      />
    {{/each}}
  
    {{#if @canCreatePost}}
      <PostVotingCommentsMenu
        @id={{@post.id}}
        @postNumber={{@post.post_number}}
        @moreCommentCount={{this.moreCommentCount}}
        @lastCommentId={{this.lastCommentId}}
        @appendComments={{this.appendComments}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "RyTDmXQn",
    "block": "[[[10,0],[14,0,\"post-voting-comments\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"comments\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@comment\",\"@removeComment\",\"@updateComment\",\"@vote\",\"@removeVote\",\"@disabled\"],[[30,1],[30,0,[\"removeComment\"]],[30,0,[\"updateComment\"]],[30,0,[\"vote\"]],[30,0,[\"removeVote\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,4],null,[[\"@id\",\"@postNumber\",\"@moreCommentCount\",\"@lastCommentId\",\"@appendComments\"],[[30,3,[\"id\"]],[30,3,[\"post_number\"]],[30,0,[\"moreCommentCount\"]],[30,0,[\"lastCommentId\"]],[30,0,[\"appendComments\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"comment\",\"@canCreatePost\",\"@post\"],false,[\"each\",\"-track-array\",\"post-voting-comment\",\"if\",\"post-voting-comments-menu\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments.hbs",
    "isStrictMode": false
  });
  let PostVotingComments = _exports.default = (_class = class PostVotingComments extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "comments", _descriptor, this);
    }
    get moreCommentCount() {
      return this.args.post.comments_count - this.comments.length;
    }
    get lastCommentId() {
      return this.comments?.[this.comments.length - 1]?.id ?? 0;
    }
    get disabled() {
      return this.args.post.topic.closed || this.args.post.topic.archived;
    }
    appendComments(comments) {
      this.comments.pushObjects(comments);
    }
    removeComment(commentId) {
      const indexToRemove = this.comments.findIndex(comment => comment.id === commentId);
      if (indexToRemove !== -1) {
        const comment = {
          ...this.comments[indexToRemove],
          deleted: true
        };
        this.comments.replace(indexToRemove, 1, [comment]);
        this.args.post.comments_count--;
      }
    }
    updateComment(comment) {
      const index = this.comments.findIndex(oldComment => oldComment.id === comment.id);
      this.comments.replace(index, 1, [comment]);
    }
    vote(commentId) {
      const index = this.comments.findIndex(oldComment => oldComment.id === commentId);
      const comment = this.comments[index];
      const updatedComment = {
        ...comment,
        post_voting_vote_count: comment.post_voting_vote_count + 1,
        user_voted: true
      };
      this.comments.replace(index, 1, [updatedComment]);
    }
    removeVote(commentId) {
      const index = this.comments.findIndex(oldComment => oldComment.id === commentId);
      const comment = this.comments[index];
      const updatedComment = {
        ...comment,
        post_voting_vote_count: comment.post_voting_vote_count - 1,
        user_voted: false
      };
      this.comments.replace(index, 1, [updatedComment]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "comments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.post.comments;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "appendComments", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "appendComments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeComment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeComment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateComment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateComment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "vote", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "vote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeVote", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeVote"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingComments);
});