define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-composer", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comment-composer">
    <textarea
      class="post-voting-comment-composer-textarea"
      value={{this.value}}
      {{on "input" this.onInput}}
      {{on "keydown" @onKeyDown}}
    ></textarea>
  
    {{#if this.value.length}}
      {{#if this.errorMessage}}
        <div class="post-voting-comment-composer-flash error">
          {{errorMessage}}
        </div>
      {{else}}
        <div class="post-voting-comment-composer-flash">
          {{i18n
            "post_voting.post.post_voting_comment.composer.length_ok"
            count=this.remainingCharacters
          }}
        </div>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "TT8XU0VE",
    "block": "[[[10,0],[14,0,\"post-voting-comment-composer\"],[12],[1,\"\\n  \"],[11,\"textarea\"],[24,0,\"post-voting-comment-composer-textarea\"],[16,2,[30,0,[\"value\"]]],[4,[38,0],[\"input\",[30,0,[\"onInput\"]]],null],[4,[38,0],[\"keydown\",[30,1]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"value\",\"length\"]],[[[41,[30,0,[\"errorMessage\"]],[[[1,\"      \"],[10,0],[14,0,\"post-voting-comment-composer-flash error\"],[12],[1,\"\\n        \"],[41,[28,[32,0],[\"errorMessage\"],null],[[[8,[39,2],null,null,null]],[]],[[[44,[[28,[37,4],null,[[\"errorMessage\"],[[28,[32,1],[\"errorMessage\"],null]]]]],[[[1,[52,[30,2,[\"errorMessage\"]],[28,[30,2,[\"errorMessage\"]],null,null],[28,[32,2],[[30,0],\"errorMessage\",\"[\\\"The `errorMessage` property path was used in the `discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-composer.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.errorMessage}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[2]]]],[]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"post-voting-comment-composer-flash\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"post_voting.post.post_voting_comment.composer.length_ok\"],[[\"count\"],[[30,0,[\"remainingCharacters\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@onKeyDown\",\"maybeHelpers\"],false,[\"on\",\"if\",\"error-message\",\"let\",\"hash\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-composer.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
  let PostVotingCommentComposer = _exports.default = (_class = class PostVotingCommentComposer extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "value", _descriptor2, this);
    }
    onInput(event) {
      this.value = event.target.value;
      this.args.onInput?.(event.target.value);
    }
    get errorMessage() {
      if (this.value.length < this.siteSettings.min_post_length) {
        return _I18n.default.t("post_voting.post.post_voting_comment.composer.too_short", {
          count: this.siteSettings.min_post_length
        });
      }
      if (this.value.length > this.siteSettings.post_voting_comment_max_raw_length) {
        return _I18n.default.t("post_voting.post.post_voting_comment.composer.too_long", {
          count: this.siteSettings.post_voting_comment_max_raw_length
        });
      }
    }
    get remainingCharacters() {
      return this.siteSettings.post_voting_comment_max_raw_length - this.value.length;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.raw ?? "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentComposer);
});