define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-button", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    {{on "click" this.onClick}}
    @disabled={{this.disabled}}
    @icon={{this.iconName}}
    class={{concat-class "btn-flat post-voting-button" this.buttonClasses}}
  />
  */
  {
    "id": "MPYYandR",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"btn-flat post-voting-button\",[30,0,[\"buttonClasses\"]]],null]],[4,[38,2],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@disabled\",\"@icon\"],[[30,0,[\"disabled\"]],[30,0,[\"iconName\"]]]],null]],[],false,[\"d-button\",\"concat-class\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-button.hbs",
    "isStrictMode": false
  });
  let PostVotingButton = _exports.default = (_class = class PostVotingButton extends _component2.default {
    get buttonClasses() {
      let classes = this.args.direction === "up" ? "post-voting-button-upvote" : "post-voting-button-downvote";
      if (this.args.voted) {
        classes += " post-voting-button-voted";
      }
      return classes;
    }
    get disabled() {
      return this.args.disabled || this.args.loading;
    }
    get iconName() {
      return this.args.direction === "up" ? "caret-up" : "caret-down";
    }
    onClick() {
      if (this.args.loading) {
        return false;
      }
      if (this.args.voted) {
        this.args.removeVote(this.args.direction);
      } else {
        this.args.vote(this.args.direction);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingButton);
});