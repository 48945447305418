define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/modal/flag", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "discourse/plugins/discourse-post-voting/discourse/lib/post-voting-flag", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _flag, _ajax, _ajaxError, _I18n, _postVotingFlag, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canEdit}}
    <span class="post-voting-comment-actions">
      <DButton
        @display="link"
        class="post-voting-comment-actions-edit-link"
        @action={{@updateComment}}
        @icon="pencil-alt"
      />
      <DButton
        @display="link"
        class="post-voting-comment-actions-delete-link"
        @action={{this.deleteConfirm}}
        @icon="far-trash-alt"
      />
  
      {{#if this.canFlag}}
        <DButton
          @display="link"
          class="post-voting-comment-actions-flag-link"
          @action={{this.showFlag}}
          @icon="flag"
        />
      {{/if}}
    </span>
  {{/if}}
  */
  {
    "id": "fwDRcrvf",
    "block": "[[[41,[30,0,[\"canEdit\"]],[[[1,\"  \"],[10,1],[14,0,\"post-voting-comment-actions\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-edit-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,1],\"pencil-alt\"]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-delete-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,0,[\"deleteConfirm\"]],\"far-trash-alt\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"canFlag\"]],[[[1,\"      \"],[8,[39,1],[[24,0,\"post-voting-comment-actions-flag-link\"]],[[\"@display\",\"@action\",\"@icon\"],[\"link\",[30,0,[\"showFlag\"]],\"flag\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@updateComment\"],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comment-actions.hbs",
    "isStrictMode": false
  });
  let PostVotingCommentActions = _exports.default = (_class = class PostVotingCommentActions extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _defineProperty(this, "comment", this.args.comment);
    }
    hasPermission() {
      return this.comment.user_id === this.currentUser.id || this.currentUser.admin || this.currentUser.moderator;
    }
    get canEdit() {
      return this.currentUser && this.hasPermission && !this.args.disabled;
    }
    get canFlag() {
      return this.currentUser && (this.hasPermission || this.currentUser.can_flag_post_voting_comments) && !this.args.disabled;
    }
    deleteConfirm() {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("post_voting.post.post_voting_comment.delete.confirm"),
        didConfirm: () => {
          const data = {
            comment_id: this.args.id
          };
          (0, _ajax.ajax)("/post_voting/comments", {
            type: "DELETE",
            data
          }).then(() => {
            this.args.removeComment(this.args.id);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    showFlag() {
      this.comment.availableFlags = this.comment.available_flags;
      this.modal.show(_flag.default, {
        model: {
          flagTarget: new _postVotingFlag.default(),
          flagModel: this.comment,
          setHidden: () => this.comment.hidden = true,
          site: this.site
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteConfirm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showFlag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showFlag"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingCommentActions);
});